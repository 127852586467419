.task {
  display: flex;
  flex-direction: column;
  // gap: 25px;
  // padding: 0 0 20px;
  // max-width: 355px;
  // height: 100%;
}

.label {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid rgba(215, 221, 240, 0.5);
  margin: 0 20px;

  @media screen and (min-width: 1000px) {
    margin: 0 20px;
  }
}

.selectContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.orgName {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 13px */
  letter-spacing: 0.13px;
}

.dateItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 13px 0 8px;
}

.dateLogo {
  display: block;
  width: 16px;
  height: 16px;
  background: url(../../../assets/task/time_white.svg) center no-repeat;
}

.dateText {
  color: #ffffff;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  line-height: 12px;
  margin: 0px 0 0;
}

.additional {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  margin: -23px 0 0;
  background-color: #ffffff;
  border-radius: 20px 20px 0 0;
  padding: 55px 36px;

  @media screen and (min-width: 1000px) {
    background-color: transparent;
    border-radius: 0;
    margin: 0 20px;
    padding: 0;
  }
}

.subtasksLabel {
  margin: 12px 0 0;
  border: none;
  border-bottom: 1px solid #bfc8e8;
  min-height: 100px;
  position: relative;
}

.text {
  color: #bfc8e8;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  margin: 0 0 10px;

  @media screen and (min-width: 1000px) {
    color: #fff;
  }
}

.subtasksItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subtaskItem {
  display: flex;
  align-items: center;
  gap: 9px;
}
