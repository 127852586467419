.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 25px 0 28px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #d9d9d9;
  grid-column-start: 1;
  grid-column-end: 4;
}

.title {
  color: #363942;
  font-size: 20px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 24px;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  & span {
    width: 28px;
    height: 28px;
    cursor: pointer;
    display: block;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }
}

.additional {
  // background: url(../../assets/subheader/subheader_additional.svg) center no-repeat;
}

.attachment {
  // background: url(../../assets/subheader/attachment_blue.svg) center no-repeat;
  position: relative;
}

.check {
  // background: url(../../assets/subheader/subheader_check.svg) center no-repeat;
}

.new {
  background: url(../../assets/subheader/new.svg) center no-repeat;
}

.edit {
  // background: url(../../assets/subheader/subheader_edit1.svg) center no-repeat;
}

.save {
  // background: url(../../assets/subheader/subheader_save.svg) center no-repeat;
}

.delete {
  // background: url(../../assets/subheader/subheader_delete.svg) center no-repeat;
  opacity: 0.2;

  &:hover {
    opacity: 0 !important;
  }
}
