.page {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  min-height: 100vh;
  position: relative;
  padding: 84px 0 0;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }

  @media screen and (min-width: 1000px) {
    display: grid;
    grid-template-columns: 216px 1fr;
    padding: 0 0 0;
  }
}

.heading {
  padding: 0 23px;
  margin: 0 0 10px;
}

.projects {
  padding: 10px 23px 100px;
  margin: 0 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input {
  margin: 10px 0 0;
  background: #f2f2f2;
  padding: 4px 8px 5px 36px;
  position: relative;
  // background-image: url("../../../../assets/logo_search.svg");
  background-repeat: no-repeat;
  background-size: 23px 23px;
  background-position: 8px 0px;
  color: #363942;
  font-size: 13px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  line-height: 16px;
  border-radius: 16px;
  width: 100%;
  max-width: 385px;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 11px;
}

.userAvatar {
  width: 29px;
  height: 29px;
}

.userName {
  color: #363942;
  text-align: center;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 23px */
  letter-spacing: 0.23px;
}

.text {
  color: #363942;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
  margin: 10px 0 0;
}

.subheading {
  color: #363942;
  font-size: 20px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 600;
  // margin: 0 0 30px;
  margin: 0 0 10px;
}

.defImage {
  max-width: 371px;
  max-height: 322px;
}
