.page {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  min-height: 90vh;
  position: relative;
  padding: 64px 0 0;
  left: 0;
  right: 0;
  max-height: calc(100vh - 134px);
  // overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }

  &_blocked {
    position: fixed;
    z-index: 5;
  }
}

.newTask {
  position: fixed;
  bottom: 100px;
  right: 20px;
  border-radius: 60px;
  background: var(--back-gradient);
  width: 60px;
  height: 60px;
  border: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }

  &::after {
    content: "+";
    display: flex;
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    align-items: center;
    justify-content: center;
  }
}
