.component {
  position: relative;
}

.heading {
  padding: 0 23px;
  margin: 0 0 10px;
}

.projects {
  padding: 10px 23px 100px;
  margin: 0 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input {
  margin: 10px 0 0;
  background: #f2f2f2;
  padding: 4px 8px 5px 36px;
  position: relative;
  // background-image: url("../../assets/logo_search.svg");
  background-repeat: no-repeat;
  background-size: 23px 23px;
  background-position: 8px 0px;
  color: #363942;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  line-height: 16px;
  border-radius: 16px;
  width: 100%;
  max-width: 385px;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 11px;
}

.userAvatar {
  width: 29px;
  height: 29px;
  border-radius: 50%;
}

.userName {
  color: #363942;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 23px */
  letter-spacing: 0.23px;
}

.items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: #363942;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.5px;
}

.text {
  color: #363942;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
  margin: 10px 0 0;
}

.subheading {
  color: #363942;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  // margin: 0 0 30px;
  margin: 0 0 10px;
}

.button {
  width: 109px;
  height: 32px;
  background: #fbfcfe;
  stroke-width: 0.5px;
  stroke: #dde4f0;
  filter: drop-shadow(0px 2px 1px rgba(64, 72, 82, 0.05));
  color: #5a6acf;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  letter-spacing: 0.5px;
}

.defImage {
  max-width: 371px;
  width: 100%;
  max-height: 322px;
}