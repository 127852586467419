.page {
  display: flex;
  flex-direction: column;
  // min-height: 100vh; // модальное окно смены профиля скроллится и футер оказывается пустым
  // padding: 64px 0 100px;
  padding: 64px 0 0;
}

.appContainer {
  max-height: calc(100vh - 134px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }
}

.heading {
  gap: 25px;
  padding: 29px 0 0;
  position: relative;
}

.formBackground {
  position: absolute;
  z-index: -1;
  background: var(--back-gradient);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    width: 300px;
    height: 360px;
    border-radius: 100%;
    opacity: 0.15;
    background: #2e3a59;
    top: -153px;
    right: -94px;
    animation: animate 15s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    width: 300px;
    height: 360px;
    border-radius: 100%;
    opacity: 0.15;
    background: #2e3a59;
    left: -84px;
    right: 0;
    bottom: -50px;
    animation: animate 30s linear infinite;
  }
}

@keyframes animate {
  0% {
    transform: translateY(100px);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(100px);
  }
}

.body {
  background-color: #ffffff;
  padding: 35px 36px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  // min-height: 80vh;
  position: relative;
}

.chatContainer {
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  padding: 0 10px;
}

.buttons {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70px;
  background-color: #ffffff;
  border-top: 1px solid var(--gray);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
  animation: show 500ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  z-index: 11;
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY((100px));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
